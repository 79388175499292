// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("jquery");
require("bootstrap");
// require("channels")

import "bootstrap";
import "../stylesheets/ress";
import "../stylesheets/style";
import "../stylesheets/notifications";
import "../stylesheets/added_books";
import "../stylesheets/allslips";
import "../stylesheets/arrival_by_repeat_slips";
import "../stylesheets/arrival_by_slips";
import "../stylesheets/header";
import "../stylesheets/kaminari";
import "../stylesheets/layouts";
import "../stylesheets/loading";
import "../stylesheets/magazines";
import "../stylesheets/modal";
import "../stylesheets/my_pages";
import "../stylesheets/notification";
import "../stylesheets/rakuten_searches";
import "../stylesheets/repeat_slips";
import "../stylesheets/slip_searches";
import "../stylesheets/slips";
import "../stylesheets/stores";
import "../stylesheets/subscriptions";
import "../stylesheets/top";
import "../stylesheets/user_searches";
import "../stylesheets/user_new";
import "../stylesheets/users";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
